import React from 'react'
import { Link } from 'react-router-dom'
import './footer_style.css'

function Footer() {
  return (
    <footer className='FooterStyle' style={{backgroundColor: "rgb(53,53,53)", padding: "2em 0", backgroundImage: `url(${require('../assets/footer_greent.png')})`, backgroundPosition: 'center'}}>
        <div style={{ width: '90%', margin: '0 auto',fontFamily: '"Raleway", sans-serif'}}>
          {/* <img src='imgicon/footer3.png' className='footerimage'></img> */}
          <p className='bolder-text' style={{color: '#000', fontSize: '15px', textAlign: 'center',fontWeight: 'bold'}}>VeSigma LLC (dba AlphaXN)</p>
          <p style={{color: '#484848', fontSize: '15px', textAlign: 'center',fontWeight: '600'}}>Copyright © 2022 AlphaXN. All rights reserved.</p>
          <p style={{color: '#484848', fontSize: '12px', textAlign: 'center',fontWeight: '600'}}><Link to='/cookie-policy' style={{color: '#484848'}}>Cookie Policy</Link></p>
          <p style={{color: '#484848', fontSize: '15px', textAlign: 'center',fontWeight: '600'}}>Site by <a style={{color: '#484848'}} href='https://sirtogo.com/' target="_blank">sirtogo.com</a></p>
        </div>
      </footer>
  )
}

export default Footer