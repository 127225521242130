import React from "react";
import "animate.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import usLogo from "../assets/us.png";
import canadaLogo from "../assets/canada.webp";
import ausLogo from "../assets/Australia.png";
import indiaLogo from "../assets/india.png"
import { useCountry } from "../comp/CountryContext";
import "./Hero.css"; // Import your CSS file

const MotionLink = motion(Link);

function Hero() {
  const { handleCountrySelect } = useCountry();

  return (
    <section className="bgwrapper" id="home">
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-once="True"
        data-aos-delay="1000"
        className="content"
      >
        <div className="mobileview">
          <h1>
            <span className="container">
              <b>AlphaXN</b>
            </span>
          </h1>
          <p className="herotxt">
            <b>Helping you unlock full potential of your business</b>
          </p>

          <div className="button-container">
            <MotionLink
              to="/aboutus"
              whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 8px #fff",
                boxShadow: "0px 0px 8px #fff",
                fill: "#34a853",
                backgroundColor: "transparent",
                color: "#fff",
                border: "2px solid #34a853",
              }}
              onClick={() => handleCountrySelect('US')}
              className="btnbtn"
            >
              <img src={usLogo} alt="US Flag" />
              US
            </MotionLink>

            <MotionLink
              to="/aboutus"
              whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 8px #fff",
                boxShadow: "0px 0px 8px #fff",
                fill: "#34a853",
                backgroundColor: "transparent",
                color: "#fff",
                border: "2px solid #34a853",
              }}
              onClick={() => handleCountrySelect('Canada')}
              className="btnbtn"
            >
              <img src={canadaLogo} alt="Canada Flag" />
              Canada
            </MotionLink>

            <MotionLink
              to="/aboutus"
              whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 8px #fff",
                boxShadow: "0px 0px 8px #fff",
                fill: "#34a853",
                backgroundColor: "transparent",
                color: "#fff",
                border: "2px solid #34a853",
              }}
              onClick={() => handleCountrySelect('Australia')}
              className="btnbtn"
            >
              <img src={ausLogo} alt="Australia Flag" />
              Australia
            </MotionLink>

            <MotionLink
              to="/aboutus"
              whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 8px #fff",
                boxShadow: "0px 0px 8px #fff",
                fill: "#34a853",
                backgroundColor: "transparent",
                color: "#fff",
                border: "2px solid #34a853",
              }}
              onClick={() => handleCountrySelect('India')}
              className="btnbtn"
            >
              <img src={indiaLogo} alt="Indian Flag" />
              India
            </MotionLink>
          </div>
        </div>
      </div>

      <div className="wrapperstyle">
        <div className="wrapperbg ">
          <motion.span className="bgwrapperspan" animate={{ scale: 1.2 }} transition={{ duration: 2, delay: 1.2 }}>
            <span className="bgwrapperspan1">
              <img alt="bg" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271968%27%20height=%271312%27/%3e" className="wrapperimg" />
            </span>

            <motion.img
              initial={{ y: -900 }}
              animate={{ y: 0 }}
              transition={{ duration: 1.2 }}
              alt="bg"
              src="imgicon/green_room_center.jpg"
              className="wrapperimg1"
              decoding="async"
            />
          </motion.span>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <div className="triangleLeft trans"></div>
        <div className="triangleRight trans2"></div>
      </div>
    </section>
  );
}

export default Hero;
