import React from 'react'
import Navbar from '../../../comp/Navbar'
import CountryServiceCard from '../us/CountryServiceCard'
import Footer from '../../../comp/Footer';
import triangleImgIcon from "../../../assets/project.jpg"

const ProjectServices = () => {
  const subServices = [
    {
      title: "Migration & Clean-up",
     
    },
    {
      title: "Cash to Accrual basis transition  ",
   
    },
    {
      title: "Internal Control Risk Assessment ",
   
    },
    {
      title: "Forensic Accounting",
   
    },
    {
      title: "Global Capability Center (‘GCC’) set-up",
   
    },
  ];
  
  return (
    <>
      <Navbar/>
      <section className="assurance-support-container">
        <h3
          style={{ marginTop: "3em", marginBottom: "1.5em" }}
          data-aos="fade"
          data-aos-duration="700"
          data-aos-once="true"
          data-aos-delay="50"
        >
          <b>Project Services</b>
        </h3>
        
        <CountryServiceCard subServices={subServices} showDetails={false} />
       
        
      </section>
      <section className="cutimage">
          <div className="cutimage1">
            <div className="emptybg"></div>
            <span className="imagestyle1">
              <span className="insidespan">
                <img
                  alt="test"
                  srcSet={triangleImgIcon}
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0px",
                    margin: "0px",
                    padding: "0px",
                  }}
                ></img>
              </span>
              <img
                alt="city"
                className="imagejpg"
                decoding="async"
                src={triangleImgIcon}
              ></img>
            </span>
          </div>
        </section>
      <Footer/>
    </>
  )
}

export default ProjectServices
