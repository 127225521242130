import React from "react";
import "./ClientsWeServe.css";
import Navbar from "../comp/Navbar";
import Footer from "../comp/Footer";
import serviceIcon from "../assets/services.jpg"
import ScrollToTop from "../comp/ScrollToTop";

const ClientsWeServe = () => {
  return (
    <>
          <Navbar />
    <div className="clients-we-serve">

      <h3
          style={{ marginTop: "3em", marginBottom: "3em" }}
          data-aos="fade"
          data-aos-duration="700"
          data-aos-once="True"
          data-aos-delay="50"
        >
          <b>Clients We Serve</b>
        </h3>
      
      {/* First row of clients */}
      <div className="clients-row">
        <div className="clients-card">
          <b>CPA firms</b>
        </div>
        <div className="divider" />
        <div className="clients-card">
          <b>Accounting firms</b>
        </div>
        <div className="divider" />
        <div className="clients-card">
          <b>Startups</b>
        </div>
        <div className="divider" />
        <div className="clients-card">
          <b>Non-profit organizations</b>
        </div>
        
        <div className="clients-card">
          <b>Growing businesses</b>
        </div>
      </div>
      
      {/* Second row of clients */}
      <div className="clients-row">
        <div className="clients-card clients-card-list-container">
          <b>Professional services firms</b>
          <ul className="clients-card-list">
            <li>Valuation services firms</li>
            <li>Forensic expert service firms</li>
            <li>Dispute resolution firms</li>
            <li>Consulting firms</li>
          </ul>
        </div>
        <div className="divider" />
        <div className="clients-card clients-card-list-container">
          <b>Private capital funds</b>
          <ul className="clients-card-list">
            <li>PE, VC, Independent Sponsors, Search Funds, etc.</li>
            <li>Real estate funds</li>
          </ul>
        </div>
      </div>

     
     
     
    </div>
    <section className="cutimage">
        <div className="cutimage_au">
          <div className="emptybg1"></div>
          <span className="imagestyle1">
            <span className="insidespan">
              <img
                alt="cut-pic"
                src={serviceIcon}
                decoding="sync"
                style={{
                  display: "block",
                  maxWidth: "100%",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: "1",
                  border: "0px",
                  margin: "0px",
                  padding: "0px",
                }}
              />
            </span>
            <img
              alt="city"
              className="imagejpg"
              decoding="async"
              src={serviceIcon}
            />
          </span>
        </div>
      </section>

    <Footer />
    <ScrollToTop/>
    </>
  );
};

export default ClientsWeServe;
