import React, { useEffect, useState } from "react"; // Import useState
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/logo_final.png";
import classNames from "classnames";
import useSticky from "./useSticky";
import { useCountry } from "./CountryContext";
import { useDropdown } from "./DropdownContext";
import "./Navbar.css";

const Navbar = () => {
  const { sticky, stickyRef } = useSticky();
  const { selectedCountry, handleCountrySelect } = useCountry();
  const { isCountriesDropdownOpen, toggleCountriesDropdown } = useDropdown();

  // State to manage the visibility of the services dropdown
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);

  const countries = ["US", "Canada", "Australia", "India"];
  const otherCountries = countries.filter(
    (country) => country !== selectedCountry
  );

  // Services based on country
  const servicesByCountry = {
    US: ["Assurance Support", "Business Services", "Project Services"],
    Canada: ["Assurance Support", "Business Services", "Project Services"],
    Australia: [
      "General Accounting",
      "Specialist Services",
      "Project Services",],
    India: ["Assurance Support", "Business Services", "Project Services"],
  };

  // Get the current pathname to determine the active subservice
  const currentPath = window.location.pathname;
  const isSubservicePage = currentPath.includes("/services/");

  const handleCountrySelectAndClose = (country) => {
    handleCountrySelect(country); // Select the country
    toggleCountriesDropdown(); // Close the dropdown
  };

  // Effect to manage the services dropdown based on the current path
  useEffect(() => {
    // If on a subservice page, keep dropdown open; otherwise, close it
    if (isSubservicePage) {
      setServicesDropdownOpen(true); // Keep dropdown open
    } else {
      setServicesDropdownOpen(false); // Close dropdown
    }
  }, [isSubservicePage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector(".country-dropdown-content");
      const button = document.querySelector(".dropbtn");

      if (
        dropdown &&
        button &&
        !dropdown.contains(event.target) &&
        !button.contains(event.target)
      ) {
        // Close the dropdown if clicked outside
        toggleCountriesDropdown(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={classNames("navbar", "upper-nav-bar", { sticky })}
        id="navbar"
        ref={stickyRef}
      >
        <div className="logo upper-nav-logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        {/* Desktop View Navigation */}
        <ul className="navm">
          <li style={{ cursor: "pointer", padding: "1rem", color: "#fff" }}>
            <NavLink className="a" to="/aboutus" activeClassName="active">
              <b>ABOUT US</b>
            </NavLink>
          </li>
          <li
            className="dropdown"
            onClick={() => setServicesDropdownOpen(!isServicesDropdownOpen)}
            style={{ cursor: "pointer", padding: "1rem", color: "#fff" }}
          >
            <span style={{ fontSize: "15px" }}>
              <b>SERVICES</b>
            </span>
            {/* Arrow Indicator */}
            <span
              className={classNames("arrow", {
                "arrow-up": isServicesDropdownOpen,
              })}
            ></span>
            {/* Dropdown for services */}
            {isServicesDropdownOpen && ( // Show dropdown if open
              <div className="service-dropdown">
                {servicesByCountry[selectedCountry].map((service, index) => (
                  <Link
                    key={index}
                    to={`/${selectedCountry.toLowerCase()}/services/${service
                      .toLowerCase()
                      .replace(/\s+/g, "-")}`}
                    className={`service-link ${
                      currentPath.includes(
                        service.toLowerCase().replace(/\s+/g, "-")
                      )
                        ? "active"
                        : ""
                    }`} // Active link styling
                  >
                    {service}
                  </Link>
                ))}
              </div>
            )}
          </li>
          <li style={{ cursor: "pointer", padding: "1rem", color: "#fff" }}>
            <NavLink
              className="a"
              to="/clients-we-serve"
              activeClassName="active"
            >
              <b>CLIENTS WE SERVE</b>
            </NavLink>
          </li>
          <li style={{ cursor: "pointer", padding: "1rem", color: "#fff" }}>
            <NavLink className="a" to="/testimonials" activeClassName="active">
              <b>TESTIMONIALS</b>
            </NavLink>
          </li>
          <li style={{ cursor: "pointer", padding: "1rem", color: "#fff" }}>
            <NavLink className="a" to="/team" activeClassName="active">
              <b>TEAM</b>
            </NavLink>
          </li>
          <li style={{ cursor: "pointer", padding: "1rem", color: "#fff" }}>
            <NavLink className="a" to="/contactus" activeClassName="active">
              <b>CONTACT US</b>
            </NavLink>
          </li>
          <li
            className="dropdown"
            style={{ cursor: "pointer", padding: "1rem", color: "#fff" }}
          >
            <span
              onClick={toggleCountriesDropdown}
              style={{
                color: "#34a853",
                marginRight: "5px",
                textTransform: "uppercase",
              }}
            >
              <b>{selectedCountry}</b>
            </span>
            <button
              onClick={toggleCountriesDropdown}
              className="dropbtn"
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              ▼
            </button>
            {isCountriesDropdownOpen && (
              <div
                className="country-dropdown-content"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  top: "100%",
                  left: 0,
                }}
              >
                {otherCountries.map((country) => (
                  <button
                    key={country}
                    onClick={() => handleCountrySelectAndClose(country)}
                    style={{
                      fontSize: "13px",

                      cursor: "pointer",
                      textAlign: "center",
                      maxWidth: "300px",
                      padding: "10%",
                      textTransform: "uppercase",
                    }}
                  >
                    {country}
                  </button>
                ))}
              </div>
            )}
          </li>
        </ul>

        {/* Mobile View Hamburger Menu */}

        {sticky && (
          <div
            style={{
              height: `${stickyRef.current?.clientHeight}px`,
            }}
          />
        )}
      </div>
      <div className="hamburger">
        <nav className="navbar navbar-dark bg-dark fixed-top">
          <div className="container-fluid">
            <div className="logo">
              <a href="/">
                <img src={Logo} alt="logo" />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDarkNavbar"
              aria-controls="offcanvasDarkNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="offcanvas offcanvas-end text-bg-dark navst"
              tabIndex="-1"
              id="offcanvasDarkNavbar"
              aria-labelledby="offcanvasDarkNavbarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                  ALPHAXN
                </h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  id="close-button"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/aboutus">
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        setServicesDropdownOpen(!isServicesDropdownOpen);
                      }}
                    >
                      Services
                    </span>
                    {isServicesDropdownOpen && (
                      <ul className="dropdown-list">
                        {servicesByCountry[selectedCountry].map(
                          (service, index) => (
                            <li key={index}>
                              <Link
                                to={`/${selectedCountry.toLowerCase()}/services/${service
                                  .toLowerCase()
                                  .replace(/\s+/g, "-")}`}
                                className={`service-link ${
                                  currentPath.includes(
                                    service.toLowerCase().replace(/\s+/g, "-")
                                  )
                                    ? "active"
                                    : ""
                                }`}
                                style={{
                                  color: currentPath.includes(
                                    service.toLowerCase().replace(/\s+/g, "-")
                                  )
                                    ? "green"
                                    : "inherit",
                                }}
                              >
                                {service}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/clients-we-serve">
                      Clients We Serve
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/testimonials">
                      Testimonials
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/team">
                      Team
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/contactus">
                      Contact Us
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link"
                      onClick={toggleCountriesDropdown}
                      style={{
                        color: "#34a853",
                        cursor: "pointer",
                        textTransform: "uppercase",
                      }}
                    >
                      {selectedCountry}
                    </span>
                    <ul
                      className={`dropdown-list ${
                        isCountriesDropdownOpen ? "show" : ""
                      }`}
                    >
                      {otherCountries.map((country) => (
                        <li key={country}>
                          <button
                            onClick={() => handleCountrySelectAndClose(country)}
                            style={{
                              fontSize: "10px",
                              padding: "5px",
                              cursor: "pointer",
                              textAlign: "center",
                              width: "100%",
                              textTransform: "uppercase",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <b>{country}</b>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
