import React from "react";
import "./ImageContainer.css"; // Import your CSS file
import icon1 from "../../assets/Team/SriKanth-removebg-preview.jpg";
import icon2 from "../../assets/Team/AMBRISH-removebg-preview.jpg";
import icon3 from "../../assets/Team/Dr.Sri-removebg-preview.jpg";
import icon4 from "../../assets/Team/paul.jpg";
import icon5 from "../../assets/Team/keyl-removebg-preview.jpg";
import icon6 from "../../assets/Team/laura-removebg-preview.jpg";
import linkedinLogo from "../../assets/linkedin_3536505.png"; // Import LinkedIn logo
import { Link } from "react-router-dom";

const ImageContainer = () => {
  const teamMembers = [
    {
      name: "Srikanth Nimmagadda,",
      title: "MBA, MPA, Chartered Accountant",
      image: icon1,
      linkedin: "https://www.linkedin.com/in/nsrikanth/",
      description: (
        <div>
          <p>
            Srikanth (Sri) is a dynamic professional with 20+ years of
            multi-domain, cross-geography experience. He is the founder of
            AlphaXN. His career journey shines with key leadership roles,
            including CEO & CFO of a business group boasting major clientele in
            the U.S. and Europe. With refined skills from top-tier organizations
            like BlackRock, Barclays Capital, Deloitte in New York, and PwC in
            India, Sri brings unparalleled knowledge and strategic acumen.
          </p>
          <p>
            An alumnus of Columbia Business School (MBA) and The University of
            Texas at Austin (Master's in Professional Accounting), Sri's
            educational background is rock-solid. Early in his career, he earned
            the esteemed Chartered Accountant qualification in India.
          </p>
          <p>
            Outside of work, you'll find Sri playing tennis or savoring moments
            with family, embodying a balanced and enriching lifestyle.
          </p>
        </div>
      ),
    },
    {
      name: "Ambrish Vadlamudi,",
      title: "CPA (U.S), Chartered Accountant",
      image: icon2,
      linkedin: "https://www.linkedin.com/in/ambrish-vadlamudi-96a41bbb/",
      description: (
        <div>
          <p>
            Ambrish is the driving force behind operations and client service in
            AlphaXN. He is the co-founder of the firm. Ambrish is a specialist
            in U.S. GAAP, masterfully handling complex accounting situations.
            With 15 years of expertise in accounting, assurance, and advisory
            services for large U.S. based client organizations, Ambrish has an
            impressive track record.
          </p>
          <p>
            His extensive work covers clients in hedge funds, private equity,
            manufacturing, and information technology industries, showcasing his
            versatile financial services acumen. He also has rich experience in
            serving CPA firms, and small businesses in the U.S.
          </p>
          <p>
            Beyond work, Ambrish is a foodie, loves to travel, and enjoys
            playing ping pong and badminton, balancing his professional prowess
            with a zest for life.
          </p>
        </div>
      ),
    },
  ];

  const Advisors = [
    {
      name: "Sri Ramamoorti,",
      title: "Associate Professor, University of Dayton, Ohio",
      image: icon3,
      linkedin: "https://www.linkedin.com/in/sridhar-ramamoorti-7966872",
      hashLink: true,
      moreLink: "/sri-ramamoorti",
      description: (
        <div>
          <p>
            Dr. Sri Ramamoorti is an Associate Professor in the Department of
            Accounting in the School of Business Administration at the
            University of Dayton, Ohio. He teaches financial and managerial
            accounting, fraud and forensic accounting, as well as accounting and
            business ethics.
          </p>
          <p>
            Possessing a blended academic-practitioner background, he was
            previously on the faculties of the University of Illinois and
            Kennesaw State University, worked as a principal at Andersen
            Worldwide, was National EY SOX Advisor, and served as a corporate
            governance partner with Grant Thornton.
          </p>
        </div>
      ),
    },
    {
      name: "Paul Herring,",
      title: "MBA, FCA, B.Sc. Monetary Economics",
      image: icon4,
      linkedin: "https://www.linkedin.com/in/paul-herring-88a0536",
      hashLink: false,
      description: (
        <div>
          <p>
            Paul is an experienced Global Innovation Leader and AI Strategist
            who drives innovation and growth through tailored consulting
            services. With an impressive track record, Paul has served as the
            Global Chief Innovation Officer at RSM International, Principal –
            Innovation, Global Head - Governance and Risk at Grant Thornton LLP,
            and Principal with global roles at Arthur Andersen in both Europe
            and the U.S.
          </p>
          <p>
            Paul's leadership extends to his tenure as Chairman of the Public
            Company Task Force and Director - Business Reporting at AICPA,
            showcasing his ability to navigate complex landscapes and deliver
            strategic insights that propel organizations forward.
          </p>
        </div>
      ),
    },
    {
      name: "Edward Keyl,",
      title: "B.A. Series 99",
      image: icon5,
      linkedin: "https://www.linkedin.com/in/edwardkeyl",
      hashLink: false,
      description: (
        <div class="shared-services-leader-profile">
          <p>
            Ed is a distinguished global shared services leader with 23 years of
            experience in financial services strategy and operations. Ed's
            expertise spans risk and controls, AML policy, fixed income, and
            prime brokerage products.
          </p>
          <p>
            Ed built UBS' shared service centers in Shanghai and Wuxi, managing
            these locations in China for 4 years. Later, he played a pivotal
            role in stabilizing and transforming UBS' Hyderabad shared service
            center in India while managing the Investment Bank Strategy and
            Operations function.
          </p>
          <p>
            Continuing his trail of success, Ed transformed UBS' Poland shared
            service center and led Group Strategy and Operations across
            Investment Bank, Asset Management, and Wealth Management. Ed's
            leadership has left an indelible mark on every center he has
            touched.
          </p>
        </div>
      ),
    },
    {
      name: "Laura Redingger,",
      title: "MBA, BS in Accounting",
      image: icon6,
      linkedin: "https://www.linkedin.com/in/laura-redinger-0167a766",
      hashLink: false,
      description: (
        <div>
          <p>
          Laura is a highly analytical leader with over 30 years of rich
            experience spanning private equity investing, capital markets/loan
            syndications, leveraged lending, commercial banking, and
            relationship building. Laura’s knack for managing
            multi-billion-dollar portfolios and identifying strategic
            investments shines through her impressive career.
          </p>
          <p>
           
            
            She served as a
            Principal with Foundation Investment Partners, a lower middle market
            Independent Sponsor, and has over 20 years of senior leadership
            experience as a Senior Vice President with KeyBanc Capital Markets
            and First Merit Bank in Northeast Ohio. Laura’s expertise in
            executing strategies in volatile markets is unparalleled. A true
            powerhouse in the financial industry, Laura’s dynamic approach and
            deep insights continue to drive growth and innovation.
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="teamImage-container">
      <h3
        style={{ marginTop: "3em", marginBottom: "3em" }}
        data-aos="fade"
        data-aos-duration="700"
        data-aos-once="True"
        data-aos-delay="50"
      >
        <b>Management Team</b>
      </h3>

      <div className="teamImage-management-card">
        {teamMembers.map((member) => (
          <div className="teamImage-card-container" key={member.id}>
            <div className="teamImage-image-card">
              <img
                src={member.image}
                alt={`${member.name}'s profile`}
                className="teamImage-profile-img"
              />
            </div>
            <div className="teamImage-profile-details">
              {/* Container for name and designation side by side */}
              <div className="teamImage-name-designation-container">
                <h3 className="teamImage-profile-name">{member.name}</h3>
                <p className="teamImage-profile-title">{member.title}</p>
              </div>

              <p className="teamImage-profile-description">
                {member.description}
              </p>

              <div className="teamImage-linkedin-container">
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={linkedinLogo}
                    alt="LinkedIn logo"
                    className="teamImage-linkedin-logo"
                  />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      <h3
        style={{ marginTop: "3em", marginBottom: "3em" }}
        data-aos="fade"
        data-aos-duration="700"
        data-aos-once="True"
        data-aos-delay="50"
      >
        <b>Advisors</b>
      </h3>

      <div className="teamImage-advisor-card">
        {Advisors.map((advisor) => (
          <div className="teamImage-card-container" key={advisor.id}>
            <div className="teamImage-image-card">
              <img
                src={advisor.image}
                alt={`${advisor.name}'s profile`}
                className="teamImage-profile-img"
              />
            </div>
            <div className="teamImage-profile-details">
              <div className="teamImage-name-designation-container">
                <h3 className="teamImage-profile-name">{advisor.name}</h3>
                <p className="teamImage-profile-title">{advisor.title}</p>
              </div>
              <p className="teamImage-profile-description">
                {advisor.description}
              </p>
              {advisor.hashLink && (
                <Link className="teamImage-more-details" to={advisor.moreLink}>
                  <p>
                    <b>More</b>
                  </p>
                </Link>
              )}

              <div className="teamImage-linkedin-container">
                <a
                  href={advisor.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={linkedinLogo}
                    alt="LinkedIn logo"
                    className="teamImage-linkedin-logo"
                  />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageContainer;
