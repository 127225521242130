import React, { createContext, useContext, useState } from 'react';

// Create the context
const CountryContext = createContext();

// Create a provider component
export const CountryProvider = ({ children }) => {
  const [selectedCountry, setSelectedCountry] = useState('US');

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  return (
    <CountryContext.Provider value={{ selectedCountry, handleCountrySelect }}>
      {children}
    </CountryContext.Provider>
  );
};

// Create a custom hook to use the CountryContext
export const useCountry = () => {
  return useContext(CountryContext);
};
