import React, { useState, useEffect } from 'react';
import './CountryServiceCard.css';
import buttonIcon from "../../../assets/right-arrow.png";

const CountryServiceCard = ({ subServices, showDetails = true }) => {
  const [selectedService, setSelectedService] = useState(null); // Default to null

  // Set the first service with a button as the selected service
  useEffect(() => {
    const firstServiceWithLink = subServices.findIndex(service => service.hasLink);
    if (firstServiceWithLink !== -1) {
      setSelectedService(firstServiceWithLink); // Set the index of the first service with a button
    }
  }, [subServices]);

  const handleArrowClick = (index) => {
    setSelectedService(selectedService === index ? null : index);
  };

  return (
    <section className="country-service-wrapper">
      <div className={`country-service-list ${!showDetails ? 'center-list' : ''}`}>
        <ul className="service-list">
          {subServices.map((service, index) => (
            <li
              key={index}
              className={`country-service-list-item ${selectedService === index ? 'active' : ''}`}
            >
              {service.title}
              {service.hasLink && (
                <button
                  className="country-service-arrow-link"
                  onClick={() => handleArrowClick(index)}
                >
                  <img
                    src={buttonIcon}
                    alt="Arrow"
                    className="country-service-arrow-icon"
                  />
                </button>
              )}
              {service.details && service.details.length > 0 && (
                <ul className="country-service-nested-list">
                  {service.details.map((detail, detailIndex) => (
                    <li key={detailIndex}>{detail}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>

      {showDetails && (
        <>
          <div className="country-service-divider"></div>
          <div className="country-service-details-container">
            {selectedService !== null && (
              <div>
                <h4><b>{subServices[selectedService].title}</b></h4>
                <p>{subServices[selectedService].paragraph}</p>
                <ul className="additional-info-list">
                  {subServices[selectedService].additionalInfo.map((info, infoIndex) => (
                    <li key={infoIndex}>{info}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default CountryServiceCard;
