import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { HashLink as Link } from "react-router-hash-link";

import "../comp/footer_style.css";

import Navbar from "../comp/Navbar";
import ScrollToTop from "../comp/ScrollToTop";

const AboutUsInfo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="main" className="about-us-conatiner">
      <Navbar/>

      <main>
        <section className="bannerstyle">
          <div className="justdiv">
            <div className="leftban1">
              <h3>
                <span
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-once="True"
                  data-aos-delay="400"
                  style={{
                    paddingRight: "53px",
                    color: "white",
                    textTransform: "none",
                  }}
                >
                  <b>About Us</b>
                </span>
                <div
                  style={{
                    width: "40px",
                    position: "absolute",
                    top: "50%",
                    right: "0",
                    maxWidth: "40px",
                    height: "1px",
                    backgroundColor: "#fff",
                  }}
                ></div>
              </h3>
            </div>
            <div className="leftbgimg">
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <span className="imagebgleft">
                    <img
                      alt="city"
                      srcSet="imgicon/city_venice1.webp"
                      className="bgimagecity"
                      decoding="sync"
                    ></img>
                    <motion.img
                      animate={{ scale: 1.2 }}
                      transition={{ duration: 3 }}
                      alt="city"
                      srcSet="imgicon/city_venice1.webp"
                      className="bgimagecity"
                      decoding="sync"
                    ></motion.img>
                  </span>
                </div>
                <motion.div
                  initial={{ y: -600 }}
                  animate={{ y: 0 }}
                  transition={{ duration: 2 }}
                  className="transparent1 image"
                ></motion.div>
              </div>
              <div className="cornerleft12"></div>
              <div className="cornerleft12_n"></div>
            </div>
            <div className="transparent3 image"></div>
          </div>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "98%",
              backgroundColor: "rgb(20,20 ,20)",
            }}
          >
            <div className="rightcontent1">
              <span className="spancontent1">
                <span>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-once="True"
                    data-aos-delay="400"
                  >
                    <p className="heading1">
                      <span style={{ color: "white", paddingTop: "0px", lineHeight: "1.5", fontFamily: "Merriweather sans" }}>
                        AlphaXN strives everyday to be your organization’s financial compass by earning your trust. AlphaXN seamlessly integrates with your team to deal with the full spectrum of business challenges, from everyday tasks to strategic initiatives in your operations
                      </span>
                    </p>
                  </div>
                </span>
              </span>
            </div>
            <div className="righttri2"></div>
          </div>
        </section>

        <div style={{ paddingBottom: "5em" }}>
          <div className="row_n">
            <div className="col-md-4">
              <div className="box_n">
                <div className="our-services_n settings" style={{ padding: "0em" }}>
                  <h4
                    className="newhead"
                    style={{ paddingTop: "1em", paddingBottom: "1em" }}
                  >
                    Your Challenges
                  </h4>
                  <div className="infobullets">
                    <p>Changing business environment</p>
                    <p>Growing complexities</p>
                    <p>Staffing and attrition rates</p>
                    <p style={{ paddingBottom: "2.5em", paddingLeft: "1.4em", paddingRight: "1.4em" }}>
                      Maintaining a high degree of transparency, timeliness in reporting, and consistency to stakeholders
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="box_n position_box">
                <div className="our-services_n privacy">
                  <h4
                    className="newhead"
                    style={{ paddingTop: "1em", paddingBottom: "1em" }}
                  >
                    Our Impact On Your Business
                  </h4>
                  <div className="infobullets">
                    <p>Quick scaling</p>
                    <p>Fixed costs converted to variable costs</p>
                    <p>Efficiency gains</p>
                    <p style={{ paddingBottom: "1.5em" }}>
                      Overcome talent shortage and attrition problems
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="cutimage">
          <div className="cutimage_au">
            <div className="emptybg1"></div>
            <span className="imagestyle1">
              <span className="insidespan">
                <img
                  alt="cut-pic"
                  src="imgicon/container_g.jpg"
                  decoding="sync"
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0px",
                    margin: "0px",
                    padding: "0px",
                  }}
                ></img>
              </span>
              <img
                alt="city"
                className="imagejpg"
                decoding="async"
                src="imgicon/container_g.jpg"
              ></img>
            </span>
          </div>
        </section>
      </main>
      <footer
        className="FooterStyle"
        style={{
          backgroundColor: "rgb(53,53,53)",
          padding: "1.4em 0",
          backgroundImage: 'url("imgicon/footer_greent.png")',
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            margin: "0 auto",
          }}
        >
          <p
            className="bolder-text"
            style={{
              color: "#000",
              fontSize: "15px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            VeSigma LLC (dba AlphaXN)
          </p>
          <p
            style={{
              color: "#484848",
              fontSize: "15px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Copyright © 2022 AlphaXN. All rights reserved.
          </p>
          <p
            style={{
              color: "#484848",
              fontSize: "12px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            <Link to="/cookie-policy" style={{ color: "#484848" }}>
              Cookie Policy
            </Link>
          </p>
          <p
            style={{
              color: "#484848",
              fontSize: "15px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Site by{" "}
            <a
              style={{ color: "#484848" }}
              href="https://sirtogo.com/"
            >
              sirtogo.com
            </a>
          </p>
        </div>
      </footer>
      <ScrollToTop />
    </div>
  );
};

export default AboutUsInfo;
