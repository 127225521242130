import React from 'react';
import './Team.css'; 
import Navbar from '../../comp/Navbar';
import ImageContainer from './ImageContainer';
import Footer from '../../comp/Footer';
import TeamIcon from "../../assets/team.jpg"
import ScrollToTop from "../../comp/ScrollToTop"
const Team = () => {
  
  
  return (
    <>
      <Navbar />
      <section className="team-section" id="team">
      <h3
          style={{ marginTop: "3em", marginBottom: "3em" }}
          data-aos="fade"
          data-aos-duration="700"
          data-aos-once="True"
          data-aos-delay="50"
        >
          <b>Team</b>
        </h3>
        <p className="introduction">
        AlphaXN goes beyond services. We build relationships with our clients, employees, and community, fostering long-term success together. We partner with you for your tax, financial operations (FinOps), auditing, and accounting needs, along the way investing in our relationships with our client, our team, our processes, and our community at large. We are a good travelmate building trust every day in your business journey.
        </p>

        <div className="impact-section">
          <h4 className="section-title">Our Impact On Your Business</h4>
          <ul className="impact-list">
            <li className="impact-item">Enabling you to say Yes to more clients</li>
            <li className="impact-item">Quick scaling with seamless transition</li>
            <li className="impact-item">Dramatic reduction in your fixed costs</li>
            <li className="impact-item">Efficiency gains</li>
            <li className="impact-item">Overcome talent shortage and attrition problems</li>
          </ul>
        </div>

        <div className="vision-mission-container">
          <div className="vision-section">
            <h2 className="section-title">Our Vision</h2>
            <p>
            AlphaXN vision is to be a go-to partner to pioneer your financial success. We empower our clients, employees, and community through exceptional services for tax, FinOps, auditing and accounting needs of our clients and community.
            </p>
          </div>

          

          <div className="mission-section">
            <h2 className="section-title">Our Mission</h2>
            <p>
            AlphaXN mission is to consistently deliver exceptional service, building trust, and achieving shared success for those who place their trust in us.
            </p>
          </div>
        </div>

       

        <div className="values-section">
          <h2 className="section-title">Our Values</h2>
          <div className="values-cards">
            <div className="value-card">
              <h3 className="value-title">Clients</h3>
              <p>Trustworthy relationships with transparent, timely and consistent communication and performance in pursuit of success for our clients.</p>
            </div>
            <div className="value-card">
              <h3 className="value-title">Employees</h3>
              <p>Nurturing healthy team spirit with professional growth and healthy work/life balance.</p>
            </div>
            <div className="value-card">
              <h3 className="value-title">Community</h3>
              <p>Passionate leadership and a community-minded staff who invests time and expertise to build a stronger future.</p>
            </div>
          </div>
        </div>

        <div className="staff-section">
          <h2 className="section-title">Staff</h2>
          <p>
            At AlphaXN, we believe in the power of a diverse team. Our dedicated staff of professionals draws experience from various industry backgrounds. This unique combination allows us to deliver practical and insightful solutions tailored to your specific needs at the pace that is most suitable to your circumstances.
          </p>
          <p>
            Committed to staying at the forefront of our field, all our staff members actively participate in continuing education through a blend of in-house, local, state, and national training programs. Furthermore, our team holds a wide range of professional credentials and memberships, demonstrating our ongoing commitment to professional excellence and staying informed about the latest industry developments.
          </p>
        </div>
      
      
      </section>
      <ImageContainer/>
      <section className="cutimage">
          <div className="cutimage_au">
            <div className="emptybg1"></div>
            <span className="imagestyle1">
              <span className="insidespan">
                <img
                  alt="cut-pic"
                  src={TeamIcon}
                  decoding="sync"
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0px",
                    margin: "0px",
                    padding: "0px",
                  }}
                ></img>
              </span>
              <img
                alt="city"
                className="imagejpg"
                decoding="async"
                src={TeamIcon}
              ></img>
            </span>
          </div>
        </section>
      <Footer/>
      <ScrollToTop/>
    </>
  );
};

export default Team;
