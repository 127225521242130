import React, { createContext, useContext, useState } from "react";

const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [isCountriesDropdownOpen, setCountriesDropdownOpen] = useState(false);

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen((prev) => !prev);
    setCountriesDropdownOpen(false); // Close countries dropdown if services is opened
  };

  const toggleCountriesDropdown = () => {
    setCountriesDropdownOpen((prev) => !prev);
    setServicesDropdownOpen(false); // Close services dropdown if countries is opened
  };

  return (
    <DropdownContext.Provider
      value={{
        isServicesDropdownOpen,
        toggleServicesDropdown,
        isCountriesDropdownOpen,
        toggleCountriesDropdown,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdown = () => useContext(DropdownContext);
