import "animate.css";
import { BrowserRouter } from "react-router-dom";
// import FloatingButtonForm from "./comp/Get-A-Quote/FloatingForm";
import { CountryProvider } from "./comp/CountryContext";
import { DropdownProvider }  from "./comp/DropdownContext"
import MainPages from "./Routes";

function App() {
  return (
    <CountryProvider>
      <DropdownProvider>
      <BrowserRouter>
        <MainPages />
        {/* <FloatingButtonForm /> */}
      </BrowserRouter>
      </DropdownProvider>
    </CountryProvider>
  );
}

export default App;
