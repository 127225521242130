import React from 'react'
import Navbar from '../../../comp/Navbar'
import CountryServiceCard from './CountryServiceCard'
import Footer from '../../../comp/Footer';
import triangleImgIcon from "../../../assets/triangle/business.jpg"
import ScrollToTop from '../../../comp/ScrollToTop';


const BusinessService = () => {
  const subServices = [
    {
      title: "AI Readiness Assessment",
      hasLink: true,
      additionalInfo: [
        "Workshops",
        "Strategy",
        "Implementation",
        "Training"
      ],
      
    },
    {
      title: "Fractional CFO Service",
     
      
    },
    {
      title: "Fractional Controller Service",
      
     
    },
    {
      title: "Financial Modelling for Pre-Deal Diligence",
    
     
    },
    {
      title: "Valuation",
      hasLink: true,
      additionalInfo: [
        "Valuation of businesses and securities (incl. employee stock plans)",
        "Valuation for tax planning (gifts), forensic analysis, dispute, M&A, divestiture, liquidation, reorganization, financial reporting, economic damages analyses, shareholder disputes, and divorce"
      ],
      
    },
    {
      title: "Quality of Earnings (QoEs)",
      
    },
    {
      title: "Waterfall Computations",
      
    },
    
    {
      title: "Alternative Dispute Resolution (ADR) Solution",
      hasLink:true,
      paragraph:"Our structured ADR process allows flexibility in execution to help you achieve an outcome tailored to your needs. Examples of domains we can assist with include:",
      additionalInfo :[
       
        " Commercial Disputes",
        " Business Valuation Disputes",
        " Construction Disputes",
        " Divorce/Domestic Matters",
        " Partnership/Shareholder Disputes",
        " Post-Acquisition (Purchase Price) Disputes"
      ]
    },
    {
      title: "New Business Setup",
      hasLink:true,
      paragraph:"Building a business is like building a house. You need a strong financial framework - that's where we come in.",
     additionalInfo:[
        " Design the suitable Chart of Accounts for all parts of your business (assets, liabilities, revenues, and expenses)",
        " Recommend and set-up the right accounting software (QuickBooks®, Sage, Xero, NetSuite, ZohoBooks, and others)",
        "Create the right set of reports",
        " Set-up the right payroll service and tools",
        "Other services such as helping you get the tax numbers, website logins, troubleshooting, etc."
      ]
    },
    {
      title: "Startup Package",
     
      
    },
    {
      title: "General Accounting",
      hasLink: true,
      
      additionalInfo: [
        "Accounting for leases",
        "Depreciation accounting",
        "Financial Reviews & Compilations",
        "AP/AR",
        "Reconciliations",
        "Payroll processing",
        "Bookkeeping including month-end and year-end closing"
      ]
    }
  ];
  
  return (
    <>
      <Navbar/>
      <section className="assurance-support-container">
        <h3
          style={{ marginTop: "3em", marginBottom: "1.5em" }}
          data-aos="fade"
          data-aos-duration="700"
          data-aos-once="true"
          data-aos-delay="50"
        >
          <b>Business Services</b>
        </h3>
        
        <CountryServiceCard subServices={subServices} />
       
       
      </section>
      <section className="cutimage">
          <div className="cutimage_au">
            <div className="emptybg1"></div>
            <span className="imagestyle1">
              <span className="insidespan">
                <img
                  alt="cut-pic"
                  src={triangleImgIcon}
                  decoding="sync"
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0px",
                    margin: "0px",
                    padding: "0px",
                  }}
                ></img>
              </span>
              <img
                alt="city"
                className="imagejpg"
                decoding="async"
                src={triangleImgIcon}
              ></img>
            </span>
          </div>
        </section>
      <Footer />
      <ScrollToTop/>
    </>
  )
}

export default BusinessService
