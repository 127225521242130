import React from 'react';
import Navbar from '../../comp/Navbar';
import Footer from '../../comp/Footer';
import icon3 from "../../assets/Team/Dr.Sri-removebg-preview.jpg";
import linkedinLogo from "../../assets/linkedin_3536505.png";
import './TeamNewPage.css'; 
import { Link } from 'react-router-dom';
import TeamIcon from "../../assets/team.jpg"

import ScrollToTop from '../../comp/ScrollToTop';

const TeamNewPage = () => {
  const Advisors = [
    {
      name: "Sri Ramamoorti",
      
      image: icon3,
      
      linkedin: "https://www.linkedin.com/in/sridhar-ramamoorti-7966872",
      moreLink: "/sri-ramamoorti",
      description: (
        <div className="professor-profile">
          <p>
            Dr. Sri Ramamoorti is an Associate Professor in the Department of Accounting in the School of Business Administration at the University of Dayton, Ohio. He teaches financial and managerial accounting, fraud and forensic accounting, as well as accounting and business ethics.
          </p>
          <p>
            Possessing a blended academic-practitioner background, he was previously on the faculties of the University of Illinois and Kennesaw State University, worked as a principal at Andersen Worldwide, was National EY SOX Advisor, and served as a corporate governance partner with Grant Thornton.
          </p>
          <p>
            He has published in academic journals such as Management Science, Journal of Management Accounting Research, Journal of Information Systems, and Issues in Accounting Education, as well as in practitioner journals such as The CPA Journal, Internal Auditor, and Strategic Finance. He is a member of the editorial board of the Journal of Information Systems as well as The CPA Journal. A former member of the Standing Advisory Group of the PCAOB, he has served on the Board of Trustees for both the Institute of Internal Auditors (IIA) and Financial Executives International (FEI) Research Foundations. He has regularly presented at AAA Annual meetings and at several professional conferences.
          </p>
          <h3>Degrees</h3>
          <ul>
            <li>Ph.D., 1995, The Ohio State University</li>
            <li>ABD/M.A. (Accounting), 1992, The Ohio State University</li>
            <li>BComm., 1984, H.R. College of Commerce, Bombay University</li>
            <li>Diploma, Mathematics & Statistics, 1982, S.I.E.S. Institute, Bombay University</li>
          </ul>
          <h3>Licenses/Certifications</h3>
          <ul>
            <li>Certified Internal Auditor (CIA), 1998-present</li>
            <li>Certified Fraud Examiner (CFE), 1995-present</li>
            <li>CPA (Ohio), 1989-present</li>
            <li>Chartered Accountant, India, 1986-present</li>
          </ul>
          <h3>Selected Publications</h3>
          <ul>
            <li>
              Burns, J.E., A. Steele, E.E. Cohen, and S. Ramamoorti. 2020. 
              <a style = {{color:"blue"}}href="https://www.coso.org/Documents/Blockchain-and-Internal-Control-The-COSO-Perspective-Guidance.pdf">Blockchain and Internal Control: The COSO Perspective. </a>Released on August 4, 2020.
            </li>
            <li>
              Balakrishnan, R., Matsumura, E. M., and Ramamoorti, S. 2019. Finding Common Ground: COSO's Control Frameworks and the Levers of Control. Journal of Management Accounting Research 31(1):63-83.
            </li>
            <li>
              Ramamoorti, S. 2018. Discussant Response to Commentary by Nolder and Palmrose, “Economic Analysis of Proposed PCAOB Standards: Finding a Path Forward” Accounting Horizons 32 (2): 201-209.
            </li>
            <li>
              Ramamoorti, S., D.L. Baskin Jr, and G.W. Krull Jr. 2017. The Gresham's law of measurement and audit quality indicators: Implications for policy making and standard-setting. Research in Accounting Regulation 29(1):79-89.
            </li>
            <li>
              Hermanson, D. R., S.E. Justice, S. Ramamoorti, and R.A. Riley, Jr. 2017. Unique characteristics of predator frauds. Journal of Forensic Accounting Research 2(1):A31-A48.
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
      <Navbar />
      <div className="team-new-page-advisor-card">
        {Advisors.map((advisor, index) => (
          <div className="team-new-page-card-container" key={index}>
            <div className="team-new-page-image-card">
              <img 
                src={advisor.image} 
                alt={`${advisor.name}'s profile`} 
                className="team-new-page-profile-img" 
              />
            </div>
            <div className="team-new-page-profile-details">
              <div className="team-new-page-name-designation-container">
                <h3 className="team-new-page-profile-name">{advisor.name}</h3>
                <p className="team-new-page-profile-title">{advisor.title}</p>
              </div>
              <p className="team-new-page-profile-description">{advisor.description}</p>
              <div className="team-new-page-linkedin-container">
                <a href={advisor.linkedin} target="_blank" rel="noopener noreferrer">
                  <img 
                    src={linkedinLogo}
                    alt="LinkedIn logo" 
                    className="team-new-page-linkedin-logo" 
                  />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="back-button-container" style={{ margin: '20px', textAlign: 'center' }}>
      <Link to="/team" className="back-button" style={{
        padding: '10px 20px',
        backgroundColor: '#34a853',
        color: 'white',
        borderRadius: '5px',
        textDecoration: 'none'
      }}>
        Back
      </Link>

    </div>
    <section className="cutimage">
          <div className="cutimage_au">
            <div className="emptybg1"></div>
            <span className="imagestyle1">
              <span className="insidespan">
                <img
                  alt="cut-pic"
                  src={TeamIcon}
                  decoding="sync"
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0px",
                    margin: "0px",
                    padding: "0px",
                  }}
                ></img>
              </span>
              <img
                alt="city"
                className="imagejpg"
                decoding="async"
                src={TeamIcon}
              ></img>
            </span>
          </div>
        </section>
      <Footer />
      <ScrollToTop/>
    </>
  );
};

export default TeamNewPage;
