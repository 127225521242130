import React from 'react'
import Navbar from '../../../comp/Navbar'
import Footer from '../../../comp/Footer'
import CountryServiceCard from '../us/CountryServiceCard'
import triangleImgIcon from "../../../assets/triangle/special.jpg"
import ScrollToTop from '../../../comp/ScrollToTop'

const SpecialistServices = () => {
  const subServices = [
    {
      title: "Financial reporting  ",
     
    },
    {
      title: "Depreciation accounting  ",
   
    },
    {
      title: "Fractional Controller role ",
   
    },
    {
      title: "Fractional CFO role",
   
    },
    {
      title: "Tax services",
   
    },
  ];
  return (
    <>
      <Navbar/>
      <section className="assurance-support-container">
        <h3
          style={{ marginTop: "3em", marginBottom: "1.5em" }}
          data-aos="fade"
          data-aos-duration="700"
          data-aos-once="true"
          data-aos-delay="50"
        >
          <b>Specialist Services</b>
        </h3>
        
        <CountryServiceCard subServices={subServices} showDetails={false} />
       
        
      </section>
      <section className="cutimage">
          <div className="cutimage_au">
            <div className="emptybg1"></div>
            <span className="imagestyle1">
              <span className="insidespan">
                <img
                  alt="cut-pic"
                  src={triangleImgIcon}
                  decoding="sync"
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0px",
                    margin: "0px",
                    padding: "0px",
                  }}
                ></img>
              </span>
              <img
                alt="city"
                className="imagejpg"
                decoding="async"
                src={triangleImgIcon}
              ></img>
            </span>
          </div>
        </section>
      <Footer/>
      <ScrollToTop/>
    </>
  )
}

export default SpecialistServices
