import React from "react";
import Navbar from "../../../comp/Navbar";

import "./SubServiceList.css";
import CountryServiceCard from "./CountryServiceCard";
import Footer from "../../../comp/Footer";
import triangleImgIcon from "../../../assets/triangle/assurance.jpg"
import ScrollToTop from "../../../comp/ScrollToTop";



const AssuranceSupport = () => {
  const subServices = [
    {
      title: "Audit Support ",
      hasLink: true,
      
      additionalInfo: [
        "Statutory Audit",
        "Internal Audit",
        "EBP Audit",
        "SOX Compliance",
        "Non-profit Audit"
      ],
    },
    {
      title: "Quality Assurance",
     
      additionalInfo: "Quality Assurance ensures that services meet certain standards and guidelines."
    },
    
    {
      title: "Internal Control Risk Assessment",
    
    },
    {
      title: "Fraud Prevention and Detection",
      
    },
    {
      title: "Forensics Support Service",
     
      
     
    },
    {
      title: "Interpretation of U.S GAAP, U.S GAAS and IFRS",
      
      
    },
    {
      title: "Preparation of Financial Statements",
     
    
    },
  ];
  
  return (
    <>
      <Navbar />
      <section className="assurance-support-container">
        <h3
          style={{ marginTop: "3em", marginBottom: "1.5em" }}
          data-aos="fade"
          data-aos-duration="700"
          data-aos-once="true"
          data-aos-delay="50"
        >
          <b>Assurance Support Services</b>
        </h3>
       
        <CountryServiceCard subServices={subServices} />
       
        
      </section>
      <section className="cutimage">
          <div className="cutimage1">
            <div className="emptybg"></div>
            <span className="imagestyle1">
              <span className="insidespan">
                <img
                  alt="test"
                  srcSet={triangleImgIcon}
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0px",
                    margin: "0px",
                    padding: "0px",
                  }}
                ></img>
              </span>
              <img
                alt="city"
                className="imagejpg"
                decoding="async"
                src={triangleImgIcon}
              ></img>
            </span>
          </div>
        </section>
      <Footer/>
      <ScrollToTop/>
    
    </>
  );
};

export default AssuranceSupport;
