import React from "react";
import { Routes, Route } from "react-router-dom";

import AboutUsInfo from "../Pages/AboutUsInfo";


import Testimonials from "../Pages/Testimonials";
import Team from "../Pages/Team/Team";
import ContactUs from "../Pages/ContactUs";
import Hero from "../comp/Hero";
import CookiePage from "../comp/CookiePage";
import AssuranceSupport from "../Pages/CountriesServices/us/AssuranceSupport";
import BusinessService from "../Pages/CountriesServices/us/BusinessService";
import ProjectServices from "../Pages/CountriesServices/us/ProjectServices";
import GeneralAccounting from "../Pages/CountriesServices/australia/GeneralAccounting";
import SpecialistServices from "../Pages/CountriesServices/australia/SpecialistServices";
import AusProjectServices from "../Pages/CountriesServices/australia/ProjectServices"
import ClientsWeServe from "../Pages/ClientsWeServe";
import TeamNewPage from "../Pages/Team/TeamNewPage";

import AssuranceIndia from "../Pages/CountriesServices/india/AssuranceSupport"
import BusinessIndia from "../Pages/CountriesServices/india/BusinessService"
import ProjectIndia from "../Pages/CountriesServices/india/ProjectServices"
const MainPages = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/aboutus" element={<AboutUsInfo />} />
        {/* <Route path="/services" element={<ServicesMain />} /> */}
        <Route path="/clients-we-serve" element={<ClientsWeServe />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/cookie-policy" element={<CookiePage />} />

        {/* <Route path="/us" element={<us />} />
          <Route path="/canada" element={<Canada />} />
          <Route path="/Australia" element={<Australia />} /> */}

        {/* us */}
        <Route path="/us/services/assurance-support" element={<AssuranceSupport/>}/>
        <Route path="/us/services/business-services" element={<BusinessService/>}/>
        <Route path="/us/services/project-services"  element={<ProjectServices/>}/>

        {/* canada */}

        <Route path="/canada/services/assurance-support" element={<AssuranceSupport/>}/>
        <Route path="/canada/services/business-services" element={<BusinessService/>}/>
        <Route path="/canada/services/project-services"  element={<ProjectServices/>}/>

        {/* australia */}
        <Route path="/australia/services/general-accounting" element={<GeneralAccounting/>}/>
        <Route path="/australia/services/specialist-services" element={<SpecialistServices/>}/>
        <Route path="/australia/services/project-services"  element={<AusProjectServices/>}/>

         {/* india */}
         <Route path="/india/services/assurance-support" element={<AssuranceIndia/>}/>
        <Route path="/india/services/business-services" element={<BusinessIndia/>}/>
        <Route path="/india/services/project-services"  element={<ProjectIndia/>}/>

        <Route path="/sri-ramamoorti" element={<TeamNewPage/>}/>
      </Routes>
   
    </>
  );
};

export default MainPages;
